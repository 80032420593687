<template>
  <div>
    <div>
      <a-form layout="inline">
        <a-form-item label="直播日期:">
          <a-date-picker
            type="datetime"
            v-model="detailForm.event_date"
            format="YYYY-MM-DD"
            valueFormat="YYYY-MM-DD"
            placeholder="选择日期"
          />
        </a-form-item>
        <a-form-item label="标题:">
          <a-input :maxLength="50" v-model="detailForm.title"/>
        </a-form-item>
        <a-form-item label="招品方向：">
          <a-textarea
            v-model="detailForm.item_categories"
            :maxLength="100"
            placeholder="请输入"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-item>

      </a-form>
      <div class="btn-lines">
        <a-button key="back" @click="handleHideThisModal()">取消</a-button>
        <a-button key="submit" type="primary" :loading="isPosting" @click="handleSubmitApplyData">提交</a-button>
      </div>
    </div>

  </div>
</template>

<script>
import {defineComponent, ref} from "@vue/composition-api";
import { postNewLive } from "@/service/sample";

export default defineComponent({
  setup(props,ctx) {
    const detailForm = ref({
      title: undefined,
      event_date: undefined,
      item_categories: ''
    });

    const handleHideThisModal = ()=> {
      ctx.emit('closeClick');
    }
    const isPosting = ref(false);
    const handleSubmitApplyData = async () => {
      if(!detailForm.value.event_date || !detailForm.value.title || !detailForm.value.item_categories) {
        ctx.root.$message.warning('请将数据完善！！！');
        return
      }
      const {res: {success, message}} = await postNewLive({
        ...detailForm.value
      });
      if (success) {
        ctx.root.$message.success('新增成功');
        ctx.emit('reload');
        ctx.emit('closeClick');
      }else {
        ctx.root.$message.error(message || '新增失败')
      }
    }
    return {
      isPosting,
      handleHideThisModal,
      handleSubmitApplyData,
      detailForm,
    }
  }
});
</script>

<style lang="less" scoped>
.swiper-images {
  width: 470px;
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
  > div {
    margin-right: 12px;
    img {
      height: 80px;
      width: 80px;
    }
  }
}
.ant-form .ant-form-item {
  margin-bottom: 18px;
  /deep/ .ant-form-item-label {
    width: 80px;
  }
  .ant-input {
    width: 480px;
  }
}
.ant-form {
  max-height: calc(100vh - 160px);
  overflow: auto;
}
.btn-lines {
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: end;
  .ant-btn:first-child {
    margin-right: 24px;
  }
}
</style>
