<template>
  <div class="shop-scheduling-list-page">
    <!-- [筛选项] -->
    <kr-card :padding="[0, 0, 16, 0]" @rect:update="onTopCardRectUpdate">
      <a-form layout="inline" :form="queryParams">
        <div class="filter-condition-form">
          <a-form-item label="日期：">
            <a-date-picker
              type="datetime"
              v-model="queryParams.event_date"
              format="YYYY-MM-DD"
              valueFormat="YYYY-MM-DD"
              placeholder="选择日期"
             />
          </a-form-item>
          <a-form-item label="标题：">
            <a-input
              v-model.trim="queryParams.title"
              allowClear
              placeholder="请输入标题"
              style="width: 190px"
              @keypress.enter.native="reload"
            />
          </a-form-item>
          <a-form-item label="审核状态：">
            <a-select
              v-model="queryParams.status"
              placeholder="审核状态"
              allowClear
              style="width: 190px"
            >
              <a-select-option v-for="(item, key) in checkStatusOptions" :key="key" :value="item.value">{{
                  item.label
                }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-button
              type="primary"
              html-type="submit"
              :loading="listLoading"
              style="margin-right: 12px"
              @click="reload"
            >查询</a-button
            >
            <a-button type="primary" @click="handleAdd">新增</a-button>
          </a-form-item>
        </div>
      </a-form>
    </kr-card>
    <!-- [列表] -->
    <a-table class="tablecl" :scroll="{ x: 1000,y: tableScrollY }" :rowKey="record => record.id" :columns="goodsColumns" :dataSource="list" :pagination="pagination" :loading="listLoading" :locale="{ emptyText: '暂无商品' }"  @change="paginationHandler">
      <template slot="status" slot-scope="record">
        <div v-if="record.status===0">选品中</div>
        <div v-if="record.status===-1">选品结束</div>
      </template>
      <template slot="operation" slot-scope="record">
        <a-button type="primary" size="small" class="mr-10" @click="goodsDetial(record)" >查看</a-button>
        <a-button v-if="record.status===0" type="primary" size="small" class="mr-10" @click="handleEndGoods(record)" >结束选品</a-button>
      </template>
    </a-table>
    <a-modal
      title="结束选品"
       @close="closeClick"
      :visible="endVisible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
    >
      <p>{{ goodsRow.title }} 结束选品</p>
    </a-modal>
    <a-drawer
      title="新增排期"
      placement="right"
      width="650"
      @close="closeClick"
      :visible="addVisible"
    >
      <add-scheduling  v-on:reload="reload" v-on:closeClick="closeClick" />
    </a-drawer>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';
import addScheduling from "@/pages/admin/sample/dialog/addScheduling";
import { endSelectGoodLive, getLiveManagementList } from "@/service/sample";

export default defineComponent({
  name: 'KrSchedulingManagement',
  components:{
    addScheduling
  },
  methods: {
    paginationHandler(params) {
      if (params.pageSize !== this.queryParams.page_size) {
        this.queryParams.page = 1;
        this.pagination.current = 1;
        this.queryParams.page_size = params.pageSize;
      } else {
        this.pagination.current = params.current;
        this.queryParams.page = params.current;
      }
      this.reload(false);
    },
    goodsDetial(row) {
      this.$router.push({ path: '/admin/sample/schedulingManagement/goods', query: { event_id: row.id } });
    },
  },
  setup(_, ctx) {
    const goodsRow = ref({});
    const endVisible = ref(false)
    const confirmLoading = ref(false)
    const handleEndGoods = (row) => {
      endVisible.value = true;
      goodsRow.value = row;
    }
    const handleOk = async () => {
      console.log('----');
      const {res: {success, message}} = await endSelectGoodLive(goodsRow.value.id,{status:-1});
      if (success) {
        ctx.root.$message.success('操作成功');
        endVisible.value=false;
        reload();
      }else {
        ctx.root.$message.error(message || '操作失败');
      }
    }

    const addVisible = ref(false)
    const handleAdd = () =>{
      addVisible.value = true;
    }

    const windowHeight = document.documentElement.clientHeight || 1000;
    const tableScrollY = ref(windowHeight - 300);
    const onTopCardRectUpdate = (rect) => {
      tableScrollY.value= windowHeight - 260 - rect.height;
    };
    // 商品列表
    const goodsColumns = [
      {
        title: "日期",
        width: 140,
        ellipsis: true,
        dataIndex: "event_date",
        customRender: (text)=> {
          return text || '--'
        },
      },
      {
        title: "标题",
        dataIndex: "title",
        width: 200,
        ellipsis: true,
        align: "left",
        customRender: (text)=> {
          return text || '--'
        },
      },
      {
        title: "招品方向",
        dataIndex: "item_categories",
        width: 200,
        ellipsis: true,
        align: "center",
        customRender: (text)=> {
          return text || '--'
        },
      },
      {
        title: "状态",
        align: 'center',
        scopedSlots: { customRender: "status" },
        width: 110,
      },
      {
        title: "已选商品",
        dataIndex: "goods_count",
        align: "center",
        width: 160,
        customRender: (text)=> {
          return text || '--'
        },
      },
      {
        title: "操作",
        align: "left",
        fixed: 'right',
        width: 180,
        scopedSlots: { customRender: "operation" },
      },
    ];

    const list = ref([]);
    const pagination = ref( {
      size: 'small',
      current: 1,
      pageSize: 10,
      pageSizeOptions: ["10", "20", "30", "40", "50"],
      showTotal: (total) =>
        `共 ${total} 条记录 第 ${pagination.value.current}/${Math.ceil(
          total / pagination.value.pageSize
        )} 页`,
      showQuickJumper: true,
      showSizeChanger: true,
      onShowSizeChange: (current, pageSize) =>
        (pagination.value.pageSize = pageSize),
    })
    const queryParams = ref({
      page: 1,
      page_size: 10,
      title: '',
      event_date: undefined,
      status: undefined
    });

    const listLoading = ref(false)
    const getSchedulingList = async () => {
      listLoading.value = true;
      const { err, res } = await getLiveManagementList( queryParams.value);
      listLoading.value = false;
      if (!err) {
        if (res.success) {
          const one_pagination = { ...pagination.value };
          list.value = res.data.results;
          one_pagination.total = res.data.count;
          pagination.value = one_pagination;
        }
      }
    };

    const reload = async (clean = true) => {
      if (clean) {
        pagination.value.current =1;
        queryParams.value.page = 1;
      }
      await getSchedulingList();
    };

    const closeClick = ()=> {
      endVisible.value = false;
      addVisible.value=false;
    };
    const checkStatusOptions = [
      {
        label: '全部',
        value: undefined,
      },
      {
        label: '选品中',
        value: 0,
      },
      {
        label: '选品结束',
        value: -1,
      }];
    return {
      handleAdd,
      addVisible,
      endVisible,
      handleOk,
      confirmLoading,
      handleEndGoods,
      checkStatusOptions,
      closeClick,
      onTopCardRectUpdate,
      goodsRow,
      pagination,
      listLoading,
      goodsColumns,
      tableScrollY,
      list,
      queryParams,
      reload
    };
  },
  created() {
    this.reload();
  },
});
</script>

<style lang="less">
.shop-scheduling-list-page {
  display: flex;
  flex-direction: column;
  .mr-10 {
    margin-right: 10px;
  }
  /deep/ .ant-table-scroll .ant-table-body {
    //样式穿透
    overflow-x: scroll !important;
  }
  .ant-table-scroll .ant-table-body {
    //样式穿透
    overflow-x: scroll !important;
  }
}
</style>

<style lang="less" scoped>
/deep/ .ant-table-scroll .ant-table-body {
  //样式穿透
  overflow-x: scroll !important;
}
.ant-table-scroll .ant-table-body {
  //样式穿透
  overflow-x: scroll !important;
}

</style>
